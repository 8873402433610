import IKDataEntity from '@/plugins/EntityFrameWork/IKDataEntity'
import { hillo } from 'hillo'

const entity = {
  fpZNum: {

  },
  createdAt: {

  },
  content: {
    tableConfig: { overwrite: true },
    displayName: 'operation'
  }
}

const config = {
  load: async function (filter) {
    return (await hillo.post('ZBon.php?op=getFPZbonRecord', {})).content
  }
}

export default IKDataEntity.ModelFactory(entity, config)

export async function printItalyZbon () {
  return (await hillo.post('ZBon.php?op=printFPZbon', {}))
}

export async function reprintFBZBon (item) {
  return (await hillo.post('ZBon.php?op=reprintFPZbon', {
    fpZNum: item
  }))
}

export async function printItalyXbon () {
  return (await hillo.post('ZBon.php?op=printFPXbon', {}))
}
